.myRow{
    display: flex;
    align-items: center;
    justify-content: center;
}

.tc-0F{
    color: #0F5FC2;
    font-size: 42px;
    font-weight: bold;
}

.tc-B5{
    color: #B5B7C0;
    font-size: 22px;
    font-weight: lighter;
}

.AdminloginButton{
    padding: 8px 90px;
    border-radius: 8px;
    color: white;
    background-color: #5932EA;
}

.h-100vh{
    height: 100vh;
}